@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap');

.app {
    width: 100%;
    height: 100%;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #222;
}

.hero {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 2rem;

    background: #FFF url(./images/home-hero-bg.png);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    position: relative;
}

.header__details {
    /* border: 1px solid red; */
    width: 100%;
    max-width: 60rem;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.header-connect {
    /* padding: 0 1rem; */
    font-size: .9rem;
    width: fit-content;
    /* padding: .5rem 1rem; */
    border-radius: 30px;
    font-family: "Roboto Mono", monospace;
    /* border: 2px solid #2F4C60; */
    color: #2F4C60;
    margin-right: 1rem;
    margin-bottom: .2rem;
}

.header-network {
    margin: 0;
    margin-right: 1rem;
    font-size: .7rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #2F4C60;
}

.header__details p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.hero h1 {
    /* border: 1px solid red; */
    margin: 0;
    font-size: 3.2rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .4rem;
    color: #2F4C60;
    text-align: center;
}

.hero > p {
    /* border: 1px solid red; */
    margin: 1rem 0 0;
    text-align: center;
    letter-spacing: .1rem;
    color: #2F4C60;
}

.hero__button-wrapper {
    /* border: 1px solid red; */
    margin: 4rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.hero__button-wrapper button {
    border: 2px solid #2F4C60;
    margin: 1rem;
    width: 15rem;
    height: 3.5rem;
    
    color: #20313C;
    font-weight: 600;
    font-size: .9rem;
    letter-spacing: .1rem;
    
    cursor: pointer;
    transition: all .1s;
}

.hero__button-wrapper button:hover {
    background: rgba(0,0,0,.1);
}

.hero__button-wrapper button a {
    /* border: 1px solid red; */
    text-decoration: none;
    color: inherit;
    width: 15rem;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.activities {
    /* border: 1px solid red; */
    width: 100%;
    background-color: #2F4C60;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activities__blockchainSwitch {
    /* border: 1px solid red; */
    background-color: #20313C;
    width: 100%;
    /* margin-top: 3rem; */
    /* padding: .7rem 2rem; */
    min-height: 3rem;
    /* padding: .5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.activities__blockchainSwitch .MuiSwitch-root {
    margin: 0 1rem;
}

.activities__blockchainSwitch span {
    font-weight: 400;
    text-transform: uppercase;
}

.activities__blockchainSwitch p {
    /* border: 1px solid red; */
    color: #EEC632;
    max-width: 63rem;
    padding: 0 1rem;
    line-height: 1.5rem;
}

.activities__wrapper {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    padding-top: 3rem;
}

.activity {
    /* border: 1px solid red; */
    width: 100%;
    max-width: 30rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
}

.activity__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    z-index: 2;
    backdrop-filter: blur(3px);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity__overlay p {
    color: #F5F1F3;
    font-weight: 500;
    margin: 0;
    padding: 1rem 2rem;
    margin: 1rem;
    border-radius: 7px;
    background: rgba(0, 0, 0, 0.85);
    line-height: 1.5rem;
}

.activity__description {
    /* border: 1px solid red; */
    border-radius: 7px;
    background-color: #F5F1F3;
    /* box-shadow: 0px 2px 5px #222; */
    padding: 1rem;
    line-height: 2rem;
    flex-basis: 50%;
    /* position: relative; */
}

.activity__description a {
    color: inherit;
    text-decoration: none;
}
.activity__description a:hover {
    color: inherit;
    text-decoration: underline;
}

.activity__description ul {
    border-radius: 5px;
    list-style-type: none;
    padding: 2rem;
}

.activity__distribution ul {
    background-color:#92ACB8;
}

.activity__contracts ul {
    border: 2px solid #92ACB8;
}

.activity__description svg {
    /* position: absolute; */
    color: #2F4C60;
}

.activity__rebase svg,
.activity__stake svg,
.activity__distribution svg,
.activity__contracts svg {
    margin-bottom: .3rem;
}

.activity__description h1 {
    color: #2F4C60;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.activity__statistics {
    /* border: 1px solid red; */
    border-radius: 7px;
    color: #F5F1F3;
    background-color: #20313C;
    margin-top: .7rem;
    /* box-shadow: 0px 2px 5px #222; */
    padding: 1.2rem 1rem 2rem;
    flex-basis: 50%;

    font-family: "Roboto Mono", monospace;
    font-weight: 400;
    /* font-size: .9rem; */

    text-align: center;
    position: relative;
}

.activity__statistics h1 {
    color: #F5F1F3;
    font-family: "Roboto Mono", monospace;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
}

.activity button {
    border-radius: 7px;
    padding: 0 2.4rem;
    margin-top: 3rem;
    width: 15rem;
    height: 3.5rem;

    font-family: "Poppins", sans-serif;
    color: #20313C;
    font-weight: 600;
    font-size: .9rem;
    /* letter-spacing: .1rem; */
    text-transform: uppercase;
    box-shadow: 0px 1px 3px #222;
    
    cursor: pointer;
    transition: all .1s;
}

.activity__rebase button {
    background-color: #2CB7EA;
}

.activity__rebase button:disabled {
    background-color: #F5F1F3;
    border: 2px solid #92ACB8;
    color: #888;
}

.activity__stake input, 
.activity__exchange input {
    height: 3.5rem;
    width: 12.5rem;
    margin-top: 3rem;
    padding: 0 1rem;
}

.activity__stake-button, 
.activity__unstake-button, 
.activity__exchange-button {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity__stake-button button {
    margin: 2rem 0 0 0;
    background-color: #EEC632;
}

.activity__rebase button:hover,
.activity__stake-button button:hover,
.activity__unstake-button button:hover,
.activity__exchange-button button:hover {
    text-decoration: underline;
}

.activity__stake-button p,
.activity__unstake-button p,
.activity__exchange-button p {
    /* border: 1px solid red; */
    margin: .1rem 0 0 0;
    width: 15rem;
    padding-left: 1rem;
    font-size: .9rem;
    text-align: left;
}

.activity__stake-button span:hover, 
.activity__unstake-button span:hover,
.activity__exchange-button span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.activity__unstake-button button {
    margin: 1rem 0 0 0;
    background-color: #92ACB8;
}

.activity__rebase .activity__statistics h1 {
    color: #53D1FF;
}

.activity__stake .activity__statistics h1 {
    color: #EEC632;
}

.activity__exchange .activity__statistics h1 {
    color: #FF7353;
}

.activity__exchange button {
    background-color: #E57055;
    margin: 2rem 0 0 0;
}

.activities__refresh {
    /* border: 1px solid red; */
    padding: 0 1rem;
    margin: 0 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #92ACB8;
}

.activities__refresh:hover {
    color: #89d4a0;
    cursor: pointer;
}

.tokenspecs {
    /* border: 1px solid red; */
    width: 100%;
    background-color: #20313C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity__contracts a {
    /* font-family: "Roboto Mono", monospace; */
    font-size: 1rem;
    margin: .5rem 0;
    margin-right: 1rem;
    line-height: 1.5rem;
    text-decoration: none;
    color: inherit;
    /* display: block; */
}

.activity__contracts a:hover {
    /* color: #20313C; */
    text-decoration: underline;
}

.activity__contracts ul li {
    font-family: "Roboto Mono", monospace;
    padding-bottom: .5rem;
    display: flex;
    flex-direction: column;
}

.activity__contracts ul li img {
    height: 18px;
    width: auto;
}

.footer {
    background-color: #222;
    height: 3.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__telegram-link button,
.footer__github-link button {
    /* border: 1px solid red; */
    border-radius: 50%;
    background-clip: padding-box;
    width: 2.5rem;
    height: 2.5rem; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__telegram-link a,
.footer__github-link a {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    color: #999;
    padding: .4rem;
}

.footer__telegram-link a:hover {
    color: #2CB7EA;
}

.footer__github-link a:hover {
    color: #E57055;
}

.app__swal {
    padding: 1rem;
    text-align: left;
}

.app__swal p {
    font-family: "Poppins", sans-serif;
    color: #111;
    -webkit-font-smoothing: none;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
}

.app__swal button {
    background-color: #646464;
    /* border: 2px solid #1C77BD; */
    border-radius: .5rem;
    padding: .5rem 2.4rem;
    margin: auto;
    margin-top: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: .8rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
}

.app__swal button a {
    /* border: 1px solid red; */
    text-decoration: none;
    /* padding: .5rem 2.4rem; */
}

.app__swal button a:active {
    color: #FFF;
}

.app__swal button a:visited {
    color: #FFF;
}


@media only screen and (min-width: 700px) {

    .hero {
        padding: 10rem 2rem;
    }

    .hero h1 {
        font-size: 3.8rem;
    }

    .hero__button-wrapper {
        width: 34rem;
        justify-content: space-between;
    }

    .hero__button-wrapper button {
        height: 2.5rem;
        font-weight: 500;
        font-size: .8rem;
    }

    .activity button {
        height: 2.5rem;
        width: 15rem;
        font-weight: 500;
        font-size: .8rem;
    }

    .hero > p {
        letter-spacing: .2rem;
    }

    .activity {
        width: auto;
        max-width: none;

        flex-direction: row;
    }

    .activities__blockchainSwitch p {
        font-size: .9rem;
    }

    .activity__description {
        max-width: 30rem;
    }

    .activity__description p {
        font-size: .9rem;
    }

    .activity__description ul li {
        font-size: .9rem;
    }

    .activity__statistics {
        margin-top: 0;
        margin-left: .7rem;
        min-width: 17rem;
    }

    .activity__statistics p {
        font-size: .9rem;
    }

    .activity__stake-button p,
    .activity__unstake-button p,
    .activity__exchange-button p {
        font-size: .8rem;
    }

    .activity__stake input, 
    .activity__exchange input {
        height: 2.5rem;
        width: 12.5rem;
        margin-top: 3rem;
        padding: 0 1rem;
        font-size: .9rem;
    }

    .activity__contracts a {
        font-size: .9rem;
    }

    .activities__refresh {
        font-size: .9rem;
        flex-direction: row;
        justify-content: center;
    }

    .activities__refresh span {
        padding-top: 0;
        padding-left: .7rem;
    }

    ::placeholder {
        font-size: .9rem;
    }

    .activities__wrapper-tokenspecs > .activity {
        width: 100%;
    }

    .activity__distribution .activity__description {
        max-width: 49.5em;
        flex-basis: 100%;
    }

    .activity__contracts .activity__description {
        max-width: 49.5rem;
        flex-basis: 100%;
    }

    .activity__contracts .activity__description .list-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .activity__contracts .activity__description .list-wrapper ul {
        flex-basis: 35%;
    }

}


@media only screen and (min-width: 1280px) {

    .hero {
        padding: 13rem 2rem;
    }

    .hero h1 {
        font-size: 4.4rem;
    }

    .activities__wrapper {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .activity {
        flex-direction: column;
        width: 22rem;
        padding: 0 2rem;
    }

    .activity__description {
        flex-basis: none;
        min-height: 23rem;
    }

    .activity__description p {
        font-size: .9rem;
    }

    .activity__statistics {
        flex-basis: none;
        margin-left: 0;
        margin-top: .7rem;
    }

    .tokenspecs {
        flex-direction: row;
        align-items: flex-start;
    }

    .tokenspecs > .activity {
        width: auto;
        max-width: none;
    }

    .tokenspecs > .activity > .activity__description {
        max-width: 32.5rem;
        min-height: inherit;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .tokenspecs > .activity > .activity__statistics {
        margin-top: 0;
        margin-left: .7rem;
        min-width: 17rem;
    }

    .activity__distribution .activity__description {
        max-width: 33em;
    }

    .activity__contracts .activity__description {
        max-width: 33rem;
    }

    .activity__contracts .activity__description .list-wrapper {
        /* flex-direction: column; */
    }

    .activity__contracts .activity__description .list-wrapper ul {
        
    }

    .activity__contracts .activity__description .list-wrapper ul li {
        /* flex-basis: 20%; */
    }

}

